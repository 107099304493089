import DashboardLayout from "@/pages/layouts/DashboardLayout";

let classRoutes = {
  path: '/classes',
  component: DashboardLayout,
  redirect: '/classes/list',
  children: [
    {
      path: 'list',
      name: 'Class List',
      component: () => import(/* webpackChunkName: "classes" */  '@/pages/classes/ClassList'),
      meta: {
        permissionsCodes: ['classes/index'],
      }
    },
    {
      path: 'create/',
      name: 'Add Class',
      component: () => import(/* webpackChunkName: "classes" */  '@/pages/classes/ClassForm'),
      meta: {
        permissionsCodes: ['classes/create'],
      }
    },
    {
      path: 'edit/:id/',
      name: 'Edit Class',
      component: () => import(/* webpackChunkName: "classes" */  '@/pages/classes/ClassForm'),
      meta: {
        permissionsCodes: ['classes/update'],
      }
    }
  ]
}

export default classRoutes;
