import DashboardLayout from "@/pages/layouts/DashboardLayout";

let projectRoutes = {
  path: '/projects',
  component: DashboardLayout,
  redirect: '/projects/list',
  children: [
    {
      path: 'list',
      name: 'Projects List',
      component: () => import(/* webpackChunkName: "projects" */  '@/pages/projects/ProjectList'),
      meta: {
        permissionsCodes: ['projects/index'],
      }
    },
    {
      path: 'create/',
      name: 'Add Project',
      component: () => import(/* webpackChunkName: "projects" */  '@/pages/projects/ProjectForm'),
      meta: {
        permissionsCodes: ['projects/create'],
      }
    },
    {
      path: 'edit/:id/',
      name: 'Edit Project',
      component: () => import(/* webpackChunkName: "projects" */  '@/pages/projects/ProjectForm'),
      meta: {
        permissionsCodes: ['projects/update'],
      }
    }
  ]
}

export default projectRoutes;
